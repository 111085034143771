<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" :isFirstValue="false" label="부서" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진 기간"
            defaultStart="-6M"
            defaultEnd="0M"
            name="checkupPeriod"
            v-model="searchParam.checkupPeriod"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="야간작업자 현황 목록"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data.filter(item => item.editFlag === 'U')"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suspect-user',
  data() {
    return {
      grid: {
        merge: [
        ],
        columns: [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '반',
            align: 'center',
            style: 'width: 100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '팀',
            align: 'center',
            style: 'width: 100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width: 100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            style: 'width: 100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            style: 'width: 100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'recommendationFlag',
            field: 'recommendationFlag',
            label: '권고대상<br>여부',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'nightWorkFlag',
            field: 'nightWorkFlag',
            label: '야간작업<br>배치여부',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'nightRemark',
            field: 'nightRemark',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '건강검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'checkupOpinion',
            field: 'checkupOpinion',
            label: '소견',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'examine4',
            field: 'examine4',
            label: '허리둘레',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine265',
            field: 'examine265',
            label: '최대혈압',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine266',
            field: 'examine266',
            label: '최소혈압',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine306',
            field: 'examine306',
            label: '혈당',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine219',
            field: 'examine219',
            label: '총콜레스테롤',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine35',
            field: 'examine35',
            label: '중성지방',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine87',
            field: 'examine87',
            label: '고밀도콜레스테롤',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine435',
            field: 'examine435',
            label: '저밀도콜레스테롤',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'examine337',
            field: 'examine337',
            label: '불면증',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'examine339',
            field: 'examine339',
            label: '위장장애',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      searchParam: {
        checkFlag: 'Y',
        plantCd: '',
        deptCd: '',
        year: '',
        userId: '',
        releaseDate: '',
        checkupPeriod: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.night.list.url;
      this.saveUrl = transactionConfig.hea.checkup.night.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data
      },);
    },
    saveData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
